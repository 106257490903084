// ignore-string-externalization

import React from 'react';
import { GetStaticPaths, GetStaticProps, NextPage } from 'next';
import { decycle, retrocycle } from 'cycle';
import { templateLoader, TemplateType, Template } from '../features/Template';
import { getLanguage } from '../utils/getLanguage';
import { useRouter } from 'next/router';
import { createClient } from 'contentful';
import memoize from 'lodash/memoize';

type Props = {
  template: TemplateType;
};

const cacheKey = 1;

/**
 * Fetches all landing pages from Contentful, and returns their slugs.
 * Responses are cached in memory for a short amount of time.
 * The caching cannot be too long because we want newly published pages to show up quickly.
 */
const getLandingPageSlugs = memoize(
  async () => {
    const api = createClient({
      host: process.env.REACT_APP_CONTENTFUL_HOST,
      environment: process.env.REACT_APP_CONTENTFUL_ENVIRONMENT,
      space: process.env.REACT_APP_CONTENTFUL_SPACE || '',
      accessToken: process.env.REACT_APP_CONTENTFUL_TOKEN!,
    });
    const resp = await api.getEntries<{ urlSlug: string }>({
      content_type: 'landingPage',
      limit: 1000,
      select: ['fields.urlSlug'],
    });

    return resp.items.map(p => p.fields.urlSlug);
  },
  () => cacheKey,
);

// clear landing page slugs cache after a minute
setInterval(() => {
  getLandingPageSlugs.cache.delete(cacheKey);
  getLandingPageSlugs();
}, 6000);

/**
 * Fetches all landing pages to prerender them
 * @see https://nextjs.org/docs/pages/building-your-application/data-fetching/get-static-props
 */
export const getStaticProps: GetStaticProps<Props> = async ctx => {
  const landingPageSlugs = await getLandingPageSlugs();
  const urlSlug = [ctx.params?.urlSlug].flat().join('/');

  // preview pages are not yet published, so they can still be previewed
  if (!ctx.preview && !landingPageSlugs.includes(urlSlug)) {
    // throw NOT_FOUND error to force a refresh of the page so that next.config redirects work
    throw new Error('NOT_FOUND');
  }

  const template = await templateLoader.load({
    contentType: 'landingPage',
    urlSlug,
    smartlingLanguage: getLanguage(ctx.locale!),
    preview: ctx.preview,
  });

  // if template is falsy (null or undefined)
  // if there's no contentType
  // we assume no valid content to show
  // and thus cannot be used to render page
  if (!template?.contentType) {
    // triggers 500.tsx error page
    throw new Error('failed to load template');
  }

  return {
    props: {
      template: decycle(template),
    },
    // Next.js will attempt to re-generate the page:
    // - When a request comes in
    // - At most once every 60 seconds
    revalidate: 60,
  };
};

/**
 * Disables server side rendering of all paths.
 * This is because we are dynamically generating the pages based on the urlSlug and Contentful paths in getStaticProps.
 * @see: https://nextjs.org/docs/pages/building-your-application/data-fetching/get-static-paths
 */
export const getStaticPaths: GetStaticPaths = () => {
  return {
    paths: [],
    fallback: 'blocking',
  };
};

export const LandingPage: NextPage<Props> = ({ template }) => {
  const router = useRouter();

  return (
    <Template
      {...retrocycle(template)}
      canonicalUrl={`/${template.urlSlug}`.replace(/^\/home$/, '/')}
      preview={router.isPreview}
    />
  );
};

export default LandingPage;
